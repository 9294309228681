import React from 'react';
import { Section } from '../../ui/Section';
import { SectionHeading } from '../../ui/SectionHeading';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDown } from 'lucide-react';
import { pricingFAQs } from '../../../data/pricing';
import { motion } from 'framer-motion';

export function FAQSection() {
  return (
    <Section className="bg-[#eeeee4]">
      <div className="max-w-3xl mx-auto">
        <SectionHeading>Frequently Asked Questions</SectionHeading>
        <motion.div 
          className="space-y-4"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          {pricingFAQs.map((faq, index) => (
            <Disclosure key={index}>
              {({ open }) => (
                <div className="bg-white rounded-lg overflow-hidden">
                  <Disclosure.Button className="flex justify-between w-full px-6 py-4 text-left focus:outline-none focus-visible:ring focus-visible:ring-[#e28743] focus-visible:ring-opacity-50">
                    <span className="font-medium text-[#154c79]">{faq.question}</span>
                    <ChevronDown
                      className={`${
                        open ? 'transform rotate-180' : ''
                      } w-5 h-5 text-[#154c79] transition-transform duration-200`}
                    />
                  </Disclosure.Button>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel className="px-6 pb-4 text-gray-600">
                      {faq.answer}
                    </Disclosure.Panel>
                  </Transition>
                </div>
              )}
            </Disclosure>
          ))}
        </motion.div>
      </div>
    </Section>
  );
}