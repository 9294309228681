import React from 'react';
import { FormField } from './FormField';
import { FormStatus } from './FormStatus';
import Button from '../ui/Button';
import { useForm } from '../../hooks/useForm';
import { validateEmail, validatePhone, validateRequired } from '../../utils/validation';

interface ContactFormData {
  name: string;
  email: string;
  phone: string;
  message: string;
}

const initialValues: ContactFormData = {
  name: '',
  email: '',
  phone: '',
  message: '',
};

export function ContactForm() {
  const validateForm = (values: ContactFormData) => {
    const errors: Partial<Record<keyof ContactFormData, string>> = {};

    if (!validateRequired(values.name)) {
      errors.name = 'Name is required';
    }

    if (!validateRequired(values.email)) {
      errors.email = 'Email is required';
    } else if (!validateEmail(values.email)) {
      errors.email = 'Please enter a valid email address';
    }

    if (values.phone && !validatePhone(values.phone)) {
      errors.phone = 'Please enter a valid phone number';
    }

    if (!validateRequired(values.message)) {
      errors.message = 'Message is required';
    }

    return errors;
  };

  const handleSubmit = async (values: ContactFormData) => {
    // Simulate API call delay
    await new Promise(resolve => setTimeout(resolve, 1000));
    console.log('Form submitted:', values);
  };

  const { 
    values, 
    errors, 
    isSubmitting, 
    status, 
    handleChange, 
    handleSubmit: onSubmit 
  } = useForm({
    initialValues,
    validate: validateForm,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={onSubmit} className="space-y-6">
      <FormField
        label="Name"
        name="name"
        value={values.name}
        onChange={handleChange}
        error={errors.name}
        required
      />

      <FormField
        label="Email"
        name="email"
        type="email"
        value={values.email}
        onChange={handleChange}
        error={errors.email}
        required
      />

      <FormField
        label="Phone"
        name="phone"
        type="tel"
        value={values.phone}
        onChange={handleChange}
        error={errors.phone}
      />

      <FormField
        label="Message"
        name="message"
        type="textarea"
        value={values.message}
        onChange={handleChange}
        error={errors.message}
        required
      />

      <FormStatus
        status={status}
        messages={{
          success: "Thank you for your message! We'll get back to you soon.",
          error: "Something went wrong. Please try again later.",
        }}
      />

      <Button
        type="submit"
        disabled={isSubmitting}
        className="w-full"
      >
        {isSubmitting ? 'Sending...' : 'Send Message'}
      </Button>
    </form>
  );
}