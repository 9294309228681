import React from 'react';
import { clsx } from 'clsx';

interface SectionHeadingProps {
  children: React.ReactNode;
  className?: string;
  centered?: boolean;
}

export function SectionHeading({ 
  children, 
  className = '', 
  centered = true 
}: SectionHeadingProps) {
  return (
    <h2 
      className={clsx(
        'text-3xl font-bold text-[#154c79] mb-12',
        centered && 'text-center',
        className
      )}
    >
      {children}
    </h2>
  );
}