import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import Button from '../ui/Button';
import { Download } from 'lucide-react';

interface QRCodeGeneratorProps {
  location: string;
  size?: number;
}

export function QRCodeGenerator({ location, size = 256 }: QRCodeGeneratorProps) {
  const reviewUrl = `${window.location.origin}/review?location=${encodeURIComponent(location)}`;

  const downloadQRCode = () => {
    const canvas = document.createElement('canvas');
    const svg = document.querySelector('svg');
    const svgData = new XMLSerializer().serializeToString(svg!);
    const img = new Image();
    
    img.onload = () => {
      canvas.width = size;
      canvas.height = size;
      const ctx = canvas.getContext('2d')!;
      ctx.drawImage(img, 0, 0);
      
      const link = document.createElement('a');
      link.download = `qr-code-${location.toLowerCase().replace(/\s+/g, '-')}.png`;
      link.href = canvas.toDataURL('image/png');
      link.click();
    };
    
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <div className="flex flex-col items-center gap-4">
      <div className="bg-white p-4 rounded-lg shadow-md">
        <QRCodeSVG
          value={reviewUrl}
          size={size}
          level="H"
          includeMargin
          imageSettings={{
            src: "/logo.png",
            x: undefined,
            y: undefined,
            height: 24,
            width: 24,
            excavate: true,
          }}
        />
      </div>
      <Button
        onClick={downloadQRCode}
        variant="secondary"
        className="flex items-center gap-2"
      >
        <Download size={20} />
        Download QR Code
      </Button>
      <p className="text-sm text-gray-600 text-center">
        Place this QR code in your location to collect customer reviews
      </p>
    </div>
  );
}