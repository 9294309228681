import React from 'react';
import { Section } from '../components/ui/Section';
import { SectionHeading } from '../components/ui/SectionHeading';
import { ContactForm } from '../components/forms/ContactForm';
import { generateMeta } from '../utils/meta';
import { siteConfig } from '../config/site';

export default function Contact() {
  const meta = generateMeta({
    title: 'Contact Us',
    description: 'Get in touch with WTF Pro for AI-powered business solutions in Edmonton.',
  });

  return (
    <Section className="py-20">
      <div className="max-w-2xl mx-auto">
        <SectionHeading>Contact Us</SectionHeading>
        
        <div className="mb-12 text-center">
          <p className="text-lg mb-4">
            Have questions? We'd love to hear from you.
          </p>
          <div className="space-y-2">
            <p>
              <strong>Email:</strong>{' '}
              <a 
                href={`mailto:${siteConfig.contact.email}`}
                className="text-[#154c79] hover:underline"
              >
                {siteConfig.contact.email}
              </a>
            </p>
            <p>
              <strong>Phone:</strong>{' '}
              <a 
                href={`tel:${siteConfig.contact.phone}`}
                className="text-[#154c79] hover:underline"
              >
                {siteConfig.contact.phone}
              </a>
            </p>
            <p>
              <strong>Location:</strong> {siteConfig.contact.address}
            </p>
          </div>
        </div>

        <div className="bg-white p-8 rounded-lg shadow-md">
          <ContactForm />
        </div>
      </div>
    </Section>
  );
}