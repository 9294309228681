export const colors = {
  primary: {
    blue: '#154c79',
    white: '#eeeee4',
  },
  secondary: {
    glacier: '#76b5c5',
    tacao: '#eab676',
  },
  accent: {
    sienna: '#e28743',
    catalina: '#063970',
  },
  text: {
    dark: '#21130d',
  },
} as const;