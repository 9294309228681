import { PricingPlan, FAQ } from '../types';

export const reviewBoosterPlans: PricingPlan[] = [
  {
    name: 'Basic',
    price: '$29',
    description: 'Perfect for small businesses getting started',
    features: [
      'Review monitoring',
      'Basic review management',
      'Email support',
      'Up to 100 reviews/month',
      'Basic analytics',
      'Single location',
    ],
  },
  {
    name: 'Pro',
    price: '$79',
    description: 'Ideal for growing businesses',
    features: [
      'Everything in Basic',
      'Advanced review analytics',
      'Priority support',
      'Unlimited reviews',
      'Custom review campaigns',
      'Multi-location support',
      'Review response templates',
      'Competitor monitoring',
    ],
    isPopular: true,
  },
  {
    name: 'Enterprise',
    price: 'Custom',
    description: 'For large organizations with specific needs',
    features: [
      'Everything in Pro',
      'Dedicated account manager',
      'Custom integrations',
      'API access',
      'White-label options',
      'Custom reporting',
      'SLA guarantees',
      'Training sessions',
    ],
  },
];

export const aiPhoneAssistantPlans: PricingPlan[] = [
  {
    name: 'Inbound Essential',
    price: '$300',
    description: 'AI-powered inbound call handling',
    features: [
      '$500 one-time setup fee',
      '24/7 automated call handling',
      'Basic call routing',
      'Voicemail transcription',
      'Email notifications',
      'Up to 500 minutes/month',
      'Basic reporting dashboard',
      'Monthly team meetings',
    ],
  },
  {
    name: 'Outbound Pro',
    price: '$500',
    description: 'Advanced outbound calling capabilities',
    features: [
      '$1,000 one-time setup fee',
      '20 simultaneous calls',
      'Call list management portal',
      'AI conversation summaries',
      'Advanced analytics dashboard',
      'Custom call scripts',
      'Monthly strategy meetings',
      '6-month minimum term',
    ],
    isPopular: true,
  },
  {
    name: 'Enterprise',
    price: 'Custom',
    description: 'Fully customized solution for large organizations',
    features: [
      'Custom setup package',
      'Unlimited simultaneous calls',
      'Custom integrations',
      'Advanced API access',
      'Dedicated account manager',
      'Priority support',
      'Weekly strategy meetings',
      'Custom contract terms',
    ],
  },
];

export const pricingFAQs: FAQ[] = [
  {
    question: 'What payment methods do you accept?',
    answer: 'We accept all major credit cards and direct bank transfers for business accounts.',
  },
  {
    question: 'Is there a setup fee?',
    answer: 'Review Booster has no setup fee. AI Phone Assistant has a one-time setup fee starting at $500 for inbound and $1,000 for outbound services.',
  },
  {
    question: 'What is the minimum commitment period?',
    answer: 'Review Booster is month-to-month. AI Phone Assistant requires a 6-month minimum commitment, then switches to month-to-month.',
  },
  {
    question: 'Do you offer a free trial?',
    answer: 'Yes, we offer a 14-day free trial for Review Booster. For AI Phone Assistant, we provide a comprehensive demo and test period during setup.',
  },
  {
    question: 'Can I upgrade or downgrade my plan?',
    answer: 'Yes, you can change plans at any time. Changes take effect at the start of your next billing cycle.',
  },
  {
    question: 'What kind of support is included?',
    answer: 'All plans include email support and monthly team meetings. Higher tiers include priority support, dedicated account managers, and more frequent strategy sessions.',
  },
];