import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import ReviewBooster from './pages/ReviewBooster';
import AIPhoneAssistant from './pages/AIPhoneAssistant';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import ReviewDashboard from './pages/review/Dashboard';
import ReviewEmbed from './pages/review/Embed';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products/review-booster" element={<ReviewBooster />} />
          <Route path="/products/ai-phone-assistant" element={<AIPhoneAssistant />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route path="/dashboard" element={<ReviewDashboard />} />
          <Route path="/review/embed" element={<ReviewEmbed />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </Router>
  );
}

function NotFound() {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <h1 className="text-2xl font-bold text-[#154c79] mb-4">Page Not Found</h1>
        <p className="text-gray-600 mb-6">The page you're looking for doesn't exist.</p>
        <a href="/" className="text-[#154c79] hover:underline">
          Return to Home
        </a>
      </div>
    </div>
  );
}

export default App;