import React from 'react';
import { Section } from '../../ui/Section';
import Button from '../../ui/Button';
import { motion } from 'framer-motion';
import { colors } from '../../../utils/colors';

export function HeroSection() {
  return (
    <Section className={`bg-[${colors.primary.blue}] text-white py-24`}>
      <div className="max-w-4xl mx-auto text-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl md:text-6xl font-bold mb-6">
            Review Booster
          </h1>
          <p className="text-xl mb-8 text-[#eeeee4]">
            Turn Happy Customers into Powerful Brand Advocates
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Button onClick={() => window.location.href = '/contact'}>
              Get Started
            </Button>
            <Button 
              variant="secondary"
              onClick={() => window.location.href = '/pricing'}
            >
              See Pricing
            </Button>
          </div>
        </motion.div>
      </div>
    </Section>
  );
}