import { siteConfig } from '../config/site';

interface MetaProps {
  title?: string;
  description?: string;
  image?: string;
}

export function generateMeta({ 
  title, 
  description = 'AI-powered solutions for business growth in Edmonton',
  image = '/og-image.jpg'
}: MetaProps = {}) {
  const finalTitle = title 
    ? `${title} | ${siteConfig.name}`
    : `${siteConfig.name} - ${siteConfig.shortName}`;

  return {
    title: finalTitle,
    description,
    image: `${siteConfig.url}${image}`,
  };
}