import { Feature, Benefit } from '../types';
import { Star, BarChart, Bell, Settings } from 'lucide-react';

export const reviewFeatures: Feature[] = [
  {
    icon: Star,
    title: 'Automated Review Collection',
    description: 'Automatically request reviews from customers at the perfect moment to maximize positive feedback.',
  },
  {
    icon: BarChart,
    title: 'Review Analytics',
    description: 'Track your online reputation with detailed analytics and insights across all major platforms.',
  },
  {
    icon: Bell,
    title: 'Review Monitoring',
    description: 'Get instant notifications when new reviews are posted so you can respond quickly.',
  },
  {
    icon: Settings,
    title: 'Review Management',
    description: 'Manage all your reviews from different platforms in one centralized dashboard.',
  },
];

export const reviewBenefits: Benefit[] = [
  {
    title: 'Increase Positive Reviews',
    description: 'Our smart timing system ensures requests go out when customers are most likely to leave positive feedback.',
  },
  {
    title: 'Improve Online Reputation',
    description: 'Build a strong online presence with a steady stream of authentic customer reviews.',
  },
  {
    title: 'Attract More Customers',
    description: 'Better reviews lead to higher search rankings and more visibility for your business.',
  },
  {
    title: 'Save Time and Effort',
    description: 'Automate the review collection process and manage everything from one dashboard.',
  },
  {
    title: 'Gain Customer Insights',
    description: 'Learn from customer feedback to improve your products and services.',
  },
];