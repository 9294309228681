import React from 'react';
import { useForm } from '../../../hooks/useForm';
import { FormField } from '../../forms/FormField';
import { FormStatus } from '../../forms/FormStatus';
import Button from '../../ui/Button';
import { validateEmail, validatePhone, validateRequired } from '../../../utils/validation';

interface DemoFormData {
  name: string;
  email: string;
  phone: string;
  company: string;
  industry: string;
}

const initialValues: DemoFormData = {
  name: '',
  email: '',
  phone: '',
  company: '',
  industry: '',
};

export function DemoForm() {
  const validateForm = (values: DemoFormData) => {
    const errors: Partial<Record<keyof DemoFormData, string>> = {};

    if (!validateRequired(values.name)) {
      errors.name = 'Name is required';
    }

    if (!validateRequired(values.email)) {
      errors.email = 'Email is required';
    } else if (!validateEmail(values.email)) {
      errors.email = 'Please enter a valid email address';
    }

    if (!validateRequired(values.phone)) {
      errors.phone = 'Phone is required';
    } else if (!validatePhone(values.phone)) {
      errors.phone = 'Please enter a valid phone number';
    }

    if (!validateRequired(values.company)) {
      errors.company = 'Company name is required';
    }

    if (!validateRequired(values.industry)) {
      errors.industry = 'Industry is required';
    }

    return errors;
  };

  const handleSubmit = async (values: DemoFormData) => {
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1000));
    console.log('Demo requested:', values);
  };

  const {
    values,
    errors,
    isSubmitting,
    status,
    handleChange,
    handleSubmit: onSubmit,
  } = useForm({
    initialValues,
    validate: validateForm,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={onSubmit} className="space-y-4">
      <FormField
        label="Name"
        name="name"
        value={values.name}
        onChange={handleChange}
        error={errors.name}
        required
      />

      <FormField
        label="Email"
        name="email"
        type="email"
        value={values.email}
        onChange={handleChange}
        error={errors.email}
        required
      />

      <FormField
        label="Phone"
        name="phone"
        type="tel"
        value={values.phone}
        onChange={handleChange}
        error={errors.phone}
        required
      />

      <FormField
        label="Company"
        name="company"
        value={values.company}
        onChange={handleChange}
        error={errors.company}
        required
      />

      <FormField
        label="Industry"
        name="industry"
        value={values.industry}
        onChange={handleChange}
        error={errors.industry}
        required
      />

      <FormStatus
        status={status}
        messages={{
          success: "Thanks for your interest! We'll contact you shortly to schedule your demo.",
          error: "Something went wrong. Please try again later.",
        }}
      />

      <Button
        type="submit"
        disabled={isSubmitting}
        className="w-full"
      >
        {isSubmitting ? 'Requesting Demo...' : 'Request Demo'}
      </Button>
    </form>
  );
}