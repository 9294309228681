import React from 'react';
import { ReviewForm } from '../../components/review/ReviewForm';
import { useSearchParams } from 'react-router-dom';

export default function Embed() {
  const [searchParams] = useSearchParams();
  const guestName = searchParams.get('guestName') || '';
  const checkoutDate = searchParams.get('checkoutDate') || '';

  return (
    <div className="p-6 bg-[#eeeee4] min-h-screen">
      <div className="max-w-xl mx-auto">
        <h1 className="text-2xl font-bold text-[#154c79] mb-6">
          Share Your Experience
        </h1>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <ReviewForm
            guestName={guestName}
            checkoutDate={checkoutDate}
          />
        </div>
      </div>
    </div>
  );
}