import React from 'react';
import { Section } from '../../ui/Section';
import { SectionHeading } from '../../ui/SectionHeading';
import { Star, Phone } from 'lucide-react';
import { colors } from '../../../utils/colors';

const features = [
  {
    icon: <Star size={32} className={`text-[${colors.primary.blue}]`} />,
    title: 'Review Booster',
    description: 'Automate review collection and improve your online reputation with our AI-powered review management system.',
    link: '/products/review-booster'
  },
  {
    icon: <Phone size={32} className={`text-[${colors.primary.blue}]`} />,
    title: 'AI Phone Assistant',
    description: 'Never miss a call again with our 24/7 AI phone system that handles calls and schedules appointments automatically.',
    link: '/products/ai-phone-assistant'
  }
];

export default function Features() {
  return (
    <Section>
      <SectionHeading>Our Solutions</SectionHeading>
      <div className="grid md:grid-cols-2 gap-8">
        {features.map((feature, index) => (
          <div 
            key={index}
            className="bg-white p-8 rounded-lg shadow-md hover:shadow-lg transition-shadow"
          >
            <div className="mb-4">{feature.icon}</div>
            <h3 className="text-2xl font-bold mb-3 text-[#154c79]">
              {feature.title}
            </h3>
            <p className="text-gray-600 mb-4">{feature.description}</p>
            <a 
              href={feature.link}
              className="text-[#154c79] font-medium hover:underline"
            >
              Learn More →
            </a>
          </div>
        ))}
      </div>
    </Section>
  );
}