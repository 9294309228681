import React from 'react';
import { HeroSection } from '../components/sections/ai-phone-assistant/HeroSection';
import { FeaturesSection } from '../components/sections/ai-phone-assistant/FeaturesSection';
import { BenefitsSection } from '../components/sections/ai-phone-assistant/BenefitsSection';
import { IndustriesSection } from '../components/sections/ai-phone-assistant/IndustriesSection';
import { DemoSection } from '../components/sections/ai-phone-assistant/DemoSection';
import { CTASection } from '../components/sections/ai-phone-assistant/CTASection';
import { generateMeta } from '../utils/meta';

export default function AIPhoneAssistant() {
  const meta = generateMeta({
    title: 'AI Phone Assistant',
    description: 'Never miss a lead or appointment again with our AI-powered phone system. 24/7 automated call handling for Edmonton businesses.',
  });

  return (
    <>
      <HeroSection />
      <FeaturesSection />
      <BenefitsSection />
      <IndustriesSection />
      <DemoSection />
      <CTASection />
    </>
  );
}