import React from 'react';
import { PricingSection } from '../components/sections/pricing/PricingSection';
import { FAQSection } from '../components/sections/pricing/FAQSection';
import { CTASection } from '../components/sections/pricing/CTASection';
import { generateMeta } from '../utils/meta';

export default function Pricing() {
  const meta = generateMeta({
    title: 'Pricing',
    description: 'Simple, transparent pricing that scales with your business. Choose the perfect plan for your needs.',
  });

  return (
    <>
      <PricingSection />
      <FAQSection />
      <CTASection />
    </>
  );
}