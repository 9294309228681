import React from 'react';
import { Link } from 'react-router-dom';
import Navigation from './navigation/Navigation';
import Footer from './footer/Footer';
import { siteConfig } from '../config/site';

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <div className="min-h-screen bg-[#eeeee4] flex flex-col">
      <header className="bg-[#154c79] text-white sticky top-0 z-50">
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <Link 
              to="/" 
              className="text-2xl font-bold hover:text-[#eab676] transition-colors"
            >
              {siteConfig.shortName}
            </Link>
            <Navigation />
          </div>
        </div>
      </header>

      <main className="flex-grow">
        {children}
      </main>

      <Footer />
    </div>
  );
}