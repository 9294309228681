import React, { useState } from 'react';
import { Section } from '../../ui/Section';
import { SectionHeading } from '../../ui/SectionHeading';
import { PricingCard } from './PricingCard';
import { reviewBoosterPlans, aiPhoneAssistantPlans } from '../../../data/pricing';
import { motion } from 'framer-motion';
import { Tab } from '@headlessui/react';
import { clsx } from 'clsx';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 }
};

export function PricingSection() {
  return (
    <Section className="bg-white">
      <div className="max-w-6xl mx-auto">
        <SectionHeading>Choose Your Plan</SectionHeading>
        <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">
          Select the perfect solution for your business needs. All plans include expert support and regular optimization meetings.
        </p>

        <Tab.Group>
          <Tab.List className="flex space-x-4 rounded-xl bg-[#eeeee4] p-2 max-w-md mx-auto mb-12">
            {['Review Booster', 'AI Phone Assistant'].map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  clsx(
                    'w-full rounded-lg py-3 text-sm font-medium leading-5',
                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-[#154c79] focus:outline-none focus:ring-2',
                    selected
                      ? 'bg-white text-[#154c79] shadow'
                      : 'text-[#154c79] hover:bg-white/[0.12] hover:text-[#154c79]'
                  )
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <motion.div 
                className="grid md:grid-cols-3 gap-8"
                variants={container}
                initial="hidden"
                animate="show"
              >
                {reviewBoosterPlans.map((plan) => (
                  <motion.div key={plan.name} variants={item}>
                    <PricingCard plan={plan} />
                  </motion.div>
                ))}
              </motion.div>
            </Tab.Panel>
            <Tab.Panel>
              <motion.div 
                className="grid md:grid-cols-3 gap-8"
                variants={container}
                initial="hidden"
                animate="show"
              >
                {aiPhoneAssistantPlans.map((plan) => (
                  <motion.div key={plan.name} variants={item}>
                    <PricingCard plan={plan} />
                  </motion.div>
                ))}
              </motion.div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

        <div className="mt-12 text-center text-sm text-gray-600">
          <p>All prices in CAD. Prices do not include applicable taxes.</p>
          <p className="mt-2">
            Need a custom solution? <a href="/contact" className="text-[#154c79] hover:underline">Contact our sales team</a>
          </p>
        </div>
      </div>
    </Section>
  );
}