import React from 'react';
import { Section } from '../../ui/Section';
import Button from '../../ui/Button';
import { colors } from '../../../utils/colors';

export default function Hero() {
  return (
    <Section 
      className={`bg-[${colors.primary.blue}] text-white py-24`}
      containerClassName="max-w-4xl mx-auto text-center"
    >
      <h1 className="text-4xl md:text-6xl font-bold mb-6">
        Transform Your Business with AI Technology
      </h1>
      <p className="text-xl mb-8 text-[#eeeee4]">
        Get more reviews, automate calls, and grow your Edmonton business with our AI-powered solutions.
      </p>
      <div className="flex flex-col sm:flex-row gap-4 justify-center">
        <Button 
          onClick={() => window.location.href = '/products/review-booster'}
        >
          Boost Your Reviews
        </Button>
        <Button 
          variant="secondary"
          onClick={() => window.location.href = '/products/ai-phone-assistant'}
        >
          Automate Your Calls
        </Button>
      </div>
    </Section>
  );
}