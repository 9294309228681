import React from 'react';
import { HeroSection } from '../components/sections/review-booster/HeroSection';
import { FeaturesSection } from '../components/sections/review-booster/FeaturesSection';
import { BenefitsSection } from '../components/sections/review-booster/BenefitsSection';
import { DemoSection } from '../components/sections/review-booster/DemoSection';
import { CTASection } from '../components/sections/review-booster/CTASection';
import { generateMeta } from '../utils/meta';

export default function ReviewBooster() {
  const meta = generateMeta({
    title: 'Review Booster',
    description: 'Turn happy customers into powerful brand advocates. Get more reviews and improve your online reputation with our AI-powered review management system.',
  });

  return (
    <>
      <HeroSection />
      <FeaturesSection />
      <BenefitsSection />
      <DemoSection />
      <CTASection />
    </>
  );
}