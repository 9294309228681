import { Feature, IndustryUseCase, Benefit } from '../types';
import { Phone, PhoneCall, Users, Calendar } from 'lucide-react';

export const phoneFeatures: Feature[] = [
  {
    icon: Phone,
    title: '24/7 Inbound Call Handling',
    description: 'Never miss a call with our AI-powered system that handles customer inquiries around the clock.',
  },
  {
    icon: PhoneCall,
    title: 'Automated Outbound Calling',
    description: 'Automate appointment reminders and follow-ups to keep your business running efficiently.',
  },
  {
    icon: Users,
    title: 'Intelligent Lead Qualification',
    description: 'Our AI system qualifies leads based on your criteria, ensuring you focus on the most promising opportunities.',
  },
  {
    icon: Calendar,
    title: 'Seamless Appointment Scheduling',
    description: 'Integrate with your calendar for automated appointment scheduling and management.',
  },
];

export const phoneBenefits: Benefit[] = [
  {
    title: 'Save Time & Resources',
    description: 'Automate routine calls and administrative tasks.',
  },
  {
    title: 'Improve Customer Service',
    description: '24/7 availability ensures no customer goes unattended.',
  },
  {
    title: 'Capture More Leads',
    description: 'Never miss an opportunity with instant response to inquiries.',
  },
  {
    title: 'Increase Booking Rates',
    description: 'Streamlined scheduling process improves conversion.',
  },
  {
    title: 'Streamline Operations',
    description: 'Integrate with your existing systems for seamless workflow.',
  },
];

export const phoneIndustries: IndustryUseCase[] = [
  {
    title: 'Restaurants',
    description: 'Handle reservations and takeout orders efficiently.',
  },
  {
    title: 'Healthcare',
    description: 'Manage patient appointments and follow-ups.',
  },
  {
    title: 'Professional Services',
    description: 'Schedule consultations and qualify leads.',
  },
  {
    title: 'Retail',
    description: 'Handle customer inquiries and support requests.',
  },
];