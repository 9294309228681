import React from 'react';
import { Check } from 'lucide-react';
import Button from '../../ui/Button';
import { PricingPlan } from '../../../types';
import { clsx } from 'clsx';

interface PricingCardProps {
  plan: PricingPlan;
}

export function PricingCard({ plan }: PricingCardProps) {
  return (
    <div
      className={clsx(
        'bg-white rounded-lg shadow-lg p-8 h-full border',
        plan.isPopular ? 'border-[#e28743]' : 'border-gray-200',
        plan.isPopular && 'relative'
      )}
    >
      {plan.isPopular && (
        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
          <span className="bg-[#e28743] text-white px-4 py-1 rounded-full text-sm font-medium">
            Most Popular
          </span>
        </div>
      )}

      <div className="text-center mb-8">
        <h3 className="text-2xl font-bold text-[#154c79] mb-2">{plan.name}</h3>
        <div className="mb-4">
          <span className="text-4xl font-bold">{plan.price}</span>
          {plan.price !== 'Custom' && <span className="text-gray-600">/month</span>}
        </div>
        <p className="text-gray-600">{plan.description}</p>
      </div>

      <ul className="space-y-4 mb-8">
        {plan.features.map((feature, index) => (
          <li key={index} className="flex items-start">
            <Check className="text-[#154c79] w-5 h-5 mt-1 mr-3 flex-shrink-0" />
            <span className="text-gray-600">{feature}</span>
          </li>
        ))}
      </ul>

      <div className="mt-auto">
        <Button
          variant={plan.isPopular ? 'primary' : 'secondary'}
          className="w-full"
          onClick={() => window.location.href = '/contact'}
        >
          {plan.price === 'Custom' ? 'Contact Sales' : 'Get Started'}
        </Button>
      </div>
    </div>
  );
}