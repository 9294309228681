import React from 'react';
import { Section } from '../../ui/Section';
import Button from '../../ui/Button';

export default function CTASection() {
  return (
    <Section className="bg-[#154c79] text-white text-center">
      <div className="max-w-3xl mx-auto">
        <h2 className="text-3xl font-bold mb-6">
          Ready to Grow Your Business?
        </h2>
        <p className="text-xl mb-8 text-[#eeeee4]">
          Join hundreds of Edmonton businesses already using our AI solutions.
        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <Button onClick={() => window.location.href = '/contact'}>
            Get Started Free
          </Button>
          <Button 
            variant="secondary"
            onClick={() => window.location.href = '/pricing'}
          >
            View Pricing
          </Button>
        </div>
      </div>
    </Section>
  );
}