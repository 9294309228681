import React from 'react';
import { useForm } from '../../hooks/useForm';
import { FormField } from '../forms/FormField';
import { FormStatus } from '../forms/FormStatus';
import Button from '../ui/Button';
import { validateRequired } from '../../utils/validation';
import { Star } from 'lucide-react';

interface ReviewFormData {
  rating: number;
  review: string;
  name: string;
  email: string;
  checkoutDate?: string;
}

interface ReviewFormProps {
  guestName?: string;
  checkoutDate?: string;
}

const initialValues: ReviewFormData = {
  rating: 0,
  review: '',
  name: '',
  email: '',
  checkoutDate: '',
};

export function ReviewForm({ guestName = '', checkoutDate = '' }: ReviewFormProps) {
  const [hoveredRating, setHoveredRating] = React.useState(0);

  const validateForm = (values: ReviewFormData) => {
    const errors: Partial<Record<keyof ReviewFormData, string>> = {};

    if (values.rating === 0) {
      errors.rating = 'Please select a rating';
    }

    if (!validateRequired(values.review)) {
      errors.review = 'Please provide a review';
    }

    if (!validateRequired(values.name)) {
      errors.name = 'Name is required';
    }

    return errors;
  };

  const handleSubmit = async (values: ReviewFormData) => {
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1000));
    console.log('Review submitted:', values);
  };

  const {
    values,
    errors,
    isSubmitting,
    status,
    handleChange,
    handleSubmit: onSubmit,
    setFieldValue,
  } = useForm({
    initialValues: {
      ...initialValues,
      name: guestName,
      checkoutDate,
    },
    validate: validateForm,
    onSubmit: handleSubmit,
  });

  const renderStars = () => {
    return (
      <div className="flex gap-2">
        {[1, 2, 3, 4, 5].map((rating) => (
          <button
            key={rating}
            type="button"
            onClick={() => setFieldValue('rating', rating)}
            onMouseEnter={() => setHoveredRating(rating)}
            onMouseLeave={() => setHoveredRating(0)}
            className="focus:outline-none"
          >
            <Star
              size={32}
              className={`${
                rating <= (hoveredRating || values.rating)
                  ? 'text-[#e28743] fill-current'
                  : 'text-gray-300'
              } transition-colors`}
            />
          </button>
        ))}
      </div>
    );
  };

  return (
    <form onSubmit={onSubmit} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Rating *
        </label>
        {renderStars()}
        {errors.rating && (
          <p className="mt-1 text-sm text-red-500">{errors.rating}</p>
        )}
      </div>

      <FormField
        label="Review"
        name="review"
        type="textarea"
        value={values.review}
        onChange={handleChange}
        error={errors.review}
        required
      />

      <FormField
        label="Name"
        name="name"
        value={values.name}
        onChange={handleChange}
        error={errors.name}
        required
      />

      <FormField
        label="Email"
        name="email"
        type="email"
        value={values.email}
        onChange={handleChange}
        error={errors.email}
      />

      <FormStatus
        status={status}
        messages={{
          success: "Thank you for your review! We appreciate your feedback.",
          error: "Something went wrong. Please try again later.",
        }}
      />

      <Button
        type="submit"
        disabled={isSubmitting}
        className="w-full"
      >
        {isSubmitting ? 'Submitting...' : 'Submit Review'}
      </Button>
    </form>
  );
}