import React from 'react';
import { Section } from '../../ui/Section';
import { SectionHeading } from '../../ui/SectionHeading';
import { DemoForm } from './DemoForm';
import { motion } from 'framer-motion';

export function DemoSection() {
  return (
    <Section className="bg-[#eeeee4]">
      <div className="max-w-6xl mx-auto">
        <SectionHeading>See the AI Phone Assistant in Action</SectionHeading>
        
        <div className="grid md:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <div className="aspect-w-16 aspect-h-9 bg-white rounded-lg shadow-md overflow-hidden">
              <iframe
                src="https://www.youtube.com/embed/dQw4w9WgXcQ"
                title="AI Phone Assistant Demo"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-full h-full"
              />
            </div>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="bg-white p-8 rounded-lg shadow-md"
          >
            <h3 className="text-2xl font-bold text-[#154c79] mb-6">Request a Demo</h3>
            <DemoForm />
          </motion.div>
        </div>
      </div>
    </Section>
  );
}