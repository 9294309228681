import React from 'react';
import { colors } from '../../../utils/colors';

interface IndustryCardProps {
  title: string;
  description: string;
}

export function IndustryCard({ title, description }: IndustryCardProps) {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow">
      <h3 className={`text-xl font-bold mb-2 text-[${colors.primary.blue}]`}>
        {title}
      </h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
}