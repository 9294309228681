import React from 'react';
import { blogPosts } from '../../data/blog';

export function BlogSidebar() {
  const categories = Array.from(
    new Set(blogPosts.map(post => post.category))
  );

  const allTags = blogPosts.flatMap(post => post.tags);
  const tags = Array.from(new Set(allTags));

  return (
    <div className="space-y-8">
      <div>
        <h3 className="text-xl font-bold text-[#154c79] mb-4">Categories</h3>
        <ul className="space-y-2">
          {categories.map((category) => (
            <li key={category}>
              <a 
                href={`/blog/category/${category.toLowerCase()}`}
                className="text-gray-600 hover:text-[#154c79]"
              >
                {category}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div>
        <h3 className="text-xl font-bold text-[#154c79] mb-4">Popular Tags</h3>
        <div className="flex flex-wrap gap-2">
          {tags.map((tag) => (
            <a
              key={tag}
              href={`/blog/tag/${tag.toLowerCase()}`}
              className="bg-[#eeeee4] text-[#154c79] px-3 py-1 rounded-full text-sm hover:bg-[#154c79] hover:text-white transition-colors"
            >
              {tag}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
}