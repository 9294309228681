import React from 'react';
import { Facebook, Linkedin, Twitter } from 'lucide-react';
import { siteConfig } from '../../config/site';

export function SocialLinks() {
  const socialIcons = [
    { icon: Linkedin, href: siteConfig.social.linkedin },
    { icon: Facebook, href: siteConfig.social.facebook },
    { icon: Twitter, href: siteConfig.social.twitter },
  ];

  return (
    <div className="flex space-x-4">
      {socialIcons.map(({ icon: Icon, href }) => (
        <a
          key={href}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-[#eab676] transition-colors"
        >
          <Icon size={24} />
        </a>
      ))}
    </div>
  );
}