import React from 'react';
import { Section } from '../../ui/Section';
import { SectionHeading } from '../../ui/SectionHeading';
import { Quote } from 'lucide-react';

const testimonials = [
  {
    content: "WTF's Review Booster has transformed how we handle customer feedback. Our online reputation has never been better!",
    author: "Sarah Johnson",
    role: "Restaurant Owner",
  },
  {
    content: "The AI Phone Assistant is incredible. We never miss a call and our booking rate has increased by 40%.",
    author: "Dr. Michael Chen",
    role: "Dental Clinic Owner",
  },
  {
    content: "Outstanding service and support. The team truly understands the needs of Edmonton businesses.",
    author: "David Wilson",
    role: "Retail Manager",
  },
];

export default function Testimonials() {
  return (
    <Section className="bg-[#eeeee4]">
      <SectionHeading>What Our Customers Say</SectionHeading>
      <div className="grid md:grid-cols-3 gap-8">
        {testimonials.map((testimonial, index) => (
          <div 
            key={index} 
            className="bg-white p-6 rounded-lg shadow-md relative"
          >
            <Quote 
              size={24} 
              className="text-[#76b5c5] absolute -top-3 -left-3"
            />
            <p className="text-gray-600 mb-4 mt-2">
              "{testimonial.content}"
            </p>
            <div className="font-medium text-[#154c79]">
              {testimonial.author}
              <span className="block text-sm text-gray-500">
                {testimonial.role}
              </span>
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
}