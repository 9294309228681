import React from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import { Section } from '../components/ui/Section';
import { BlogSidebar } from '../components/blog/BlogSidebar';
import { blogPosts } from '../data/blog';
import { generateMeta } from '../utils/meta';
import { Calendar, Tag } from 'lucide-react';

export default function BlogPost() {
  const { id } = useParams();
  const post = blogPosts.find(post => post.id === id);

  if (!post) {
    return (
      <Layout>
        <Section className="bg-white py-12">
          <div className="text-center">
            <h1 className="text-2xl font-bold text-[#154c79] mb-4">Post Not Found</h1>
            <p className="text-gray-600">The blog post you're looking for doesn't exist.</p>
            <a href="/blog" className="text-[#154c79] hover:underline mt-4 inline-block">
              Return to Blog
            </a>
          </div>
        </Section>
      </Layout>
    );
  }

  const meta = generateMeta({
    title: post.title,
    description: post.excerpt,
    image: post.image,
  });

  return (
    <Layout>
      <Section className="bg-white py-12">
        <div className="grid lg:grid-cols-12 gap-8">
          <div className="lg:col-span-8">
            <article>
              <div className="aspect-w-16 aspect-h-9 mb-8">
                <img 
                  src={post.image} 
                  alt={post.title}
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>

              <div className="flex items-center gap-4 text-sm text-gray-600 mb-4">
                <span className="flex items-center gap-1">
                  <Calendar size={16} />
                  {post.date}
                </span>
                <span className="flex items-center gap-1">
                  <Tag size={16} />
                  {post.category}
                </span>
              </div>

              <h1 className="text-4xl font-bold text-[#154c79] mb-6">
                {post.title}
              </h1>

              <div className="prose prose-lg max-w-none">
                {post.content.split('\n').map((paragraph, index) => (
                  <p key={index} className="mb-4 text-gray-600">
                    {paragraph}
                  </p>
                ))}
              </div>

              <div className="mt-8 pt-8 border-t border-gray-200">
                <div className="flex flex-wrap gap-2">
                  {post.tags.map((tag) => (
                    <span 
                      key={tag}
                      className="bg-[#eeeee4] text-[#154c79] px-3 py-1 rounded-full text-sm"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </article>
          </div>

          <div className="lg:col-span-4">
            <BlogSidebar />
          </div>
        </div>
      </Section>
    </Layout>
  );
}