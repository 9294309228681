import React from 'react';
import { Link } from 'react-router-dom';
import { footerSections } from '../../data/navigation';
import { siteConfig } from '../../config/site';
import { SocialLinks } from '../ui/SocialLinks';

export default function Footer() {
  return (
    <footer className="bg-[#154c79] text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-lg font-bold mb-4">About Us</h3>
            <p className="text-[#eeeee4]">
              WTF Pro provides AI-powered solutions to help businesses in Edmonton grow.
            </p>
          </div>

          {footerSections.map((section, index) => (
            <div key={index}>
              <h3 className="text-lg font-bold mb-4">{section.title}</h3>
              <ul className="space-y-2">
                {section.links?.map((link) => (
                  <li key={link.href}>
                    <Link 
                      to={link.href}
                      className="text-[#eeeee4] hover:text-[#eab676] transition-colors"
                    >
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}

          <div>
            <h3 className="text-lg font-bold mb-4">Contact</h3>
            <ul className="space-y-2 text-[#eeeee4]">
              <li>
                <a 
                  href={`mailto:${siteConfig.contact.email}`}
                  className="hover:text-[#eab676] transition-colors"
                >
                  {siteConfig.contact.email}
                </a>
              </li>
              <li>
                <a 
                  href={`tel:${siteConfig.contact.phone}`}
                  className="hover:text-[#eab676] transition-colors"
                >
                  {siteConfig.contact.phone}
                </a>
              </li>
              <li>{siteConfig.contact.address}</li>
            </ul>
            <div className="mt-4">
              <h4 className="text-sm font-bold mb-2">Follow Us</h4>
              <SocialLinks />
            </div>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-[#eeeee4]/20 text-center text-sm text-[#eeeee4]">
          © {new Date().getFullYear()} {siteConfig.name}. All rights reserved.
        </div>
      </div>
    </footer>
  );
}