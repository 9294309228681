import React from 'react';
import { Section } from '../../ui/Section';
import Button from '../../ui/Button';
import { motion } from 'framer-motion';

export function CTASection() {
  return (
    <Section className="bg-[#eeeee4] text-center">
      <motion.div 
        className="max-w-2xl mx-auto"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-3xl font-bold mb-6 text-[#154c79]">
          Ready to Boost Your Online Reputation?
        </h2>
        <p className="text-gray-600 mb-8">
          Join hundreds of Edmonton businesses already using Review Booster to improve their online presence.
        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <Button onClick={() => window.location.href = '/contact'}>
            Get Started Today
          </Button>
          <Button 
            variant="secondary"
            onClick={() => window.location.href = '/contact'}
          >
            Contact Sales
          </Button>
        </div>
      </motion.div>
    </Section>
  );
}