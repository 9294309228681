import React from 'react';
import { Section } from '../components/ui/Section';
import { SectionHeading } from '../components/ui/SectionHeading';
import { BlogGrid } from '../components/blog/BlogGrid';
import { BlogSidebar } from '../components/blog/BlogSidebar';
import { generateMeta } from '../utils/meta';
import { blogPosts } from '../data/blog';

export default function Blog() {
  const meta = generateMeta({
    title: 'Blog',
    description: 'Latest insights on business growth, online reviews, and AI technology for Edmonton businesses.',
  });

  return (
    <Section className="bg-white py-12">
      <SectionHeading>Latest Insights</SectionHeading>
      <div className="grid lg:grid-cols-12 gap-8">
        <div className="lg:col-span-8">
          <BlogGrid posts={blogPosts} />
        </div>
        <div className="lg:col-span-4">
          <BlogSidebar />
        </div>
      </div>
    </Section>
  );
}