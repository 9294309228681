import React from 'react';
import { Section } from '../../ui/Section';
import { SectionHeading } from '../../ui/SectionHeading';
import { Building2, Stethoscope, Store, Briefcase } from 'lucide-react';

const industries = [
  {
    icon: <Building2 size={32} />,
    title: 'Hospitality',
    description: 'Perfect for restaurants, hotels, and entertainment venues.',
  },
  {
    icon: <Store size={32} />,
    title: 'Retail',
    description: 'Ideal for stores, boutiques, and retail chains.',
  },
  {
    icon: <Stethoscope size={32} />,
    title: 'Healthcare',
    description: 'Designed for clinics, dental offices, and medical practices.',
  },
  {
    icon: <Briefcase size={32} />,
    title: 'Professional Services',
    description: 'Built for lawyers, accountants, and consultants.',
  },
];

export default function Industries() {
  return (
    <Section className="bg-[#eeeee4]">
      <SectionHeading>Industries We Serve</SectionHeading>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
        {industries.map((industry, index) => (
          <div 
            key={index}
            className="bg-white p-6 rounded-lg shadow-md text-center hover:shadow-lg transition-shadow"
          >
            <div className="text-[#154c79] mb-4 flex justify-center">
              {industry.icon}
            </div>
            <h3 className="text-xl font-bold mb-2 text-[#154c79]">
              {industry.title}
            </h3>
            <p className="text-gray-600">{industry.description}</p>
          </div>
        ))}
      </div>
    </Section>
  );
}