import React from 'react';
import { Section } from '../../ui/Section';
import { SectionHeading } from '../../ui/SectionHeading';
import { FeatureCard } from './FeatureCard';
import { reviewFeatures } from '../../../data/reviewBooster';
import { motion } from 'framer-motion';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 }
};

export function FeaturesSection() {
  return (
    <Section className="bg-white">
      <SectionHeading>Key Features</SectionHeading>
      <motion.div 
        className="grid md:grid-cols-2 lg:grid-cols-4 gap-8"
        variants={container}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
      >
        {reviewFeatures.map((feature, index) => (
          <motion.div key={index} variants={item}>
            <FeatureCard
              icon={<feature.icon className="w-6 h-6" />}
              title={feature.title}
              description={feature.description}
            />
          </motion.div>
        ))}
      </motion.div>
    </Section>
  );
}