import React from 'react';
import { Section } from '../../ui/Section';
import { SectionHeading } from '../../ui/SectionHeading';
import { BenefitCard } from './BenefitCard';
import { reviewBenefits } from '../../../data/reviewBooster';
import { motion } from 'framer-motion';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 }
};

export function BenefitsSection() {
  return (
    <Section className="bg-[#eeeee4]">
      <SectionHeading>How Review Booster Helps Your Business</SectionHeading>
      <motion.div 
        className="grid md:grid-cols-2 lg:grid-cols-3 gap-8"
        variants={container}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
      >
        {reviewBenefits.map((benefit, index) => (
          <motion.div key={index} variants={item}>
            <BenefitCard
              title={benefit.title}
              description={benefit.description}
            />
          </motion.div>
        ))}
      </motion.div>
    </Section>
  );
}