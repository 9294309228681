import React from 'react';
import { clsx } from 'clsx';
import { Container } from './Container';

interface SectionProps {
  children: React.ReactNode;
  className?: string;
  containerClassName?: string;
  fullWidth?: boolean;
}

export function Section({ 
  children, 
  className = '', 
  containerClassName = '',
  fullWidth = false,
}: SectionProps) {
  return (
    <section className={clsx('py-12 sm:py-16 md:py-20 lg:py-24', className)}>
      {fullWidth ? (
        children
      ) : (
        <Container className={containerClassName}>
          {children}
        </Container>
      )}
    </section>
  );
}