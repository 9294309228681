import React from 'react';
import { clsx } from 'clsx';
import { colors } from '../../../utils/colors';

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  className?: string;
}

export function FeatureCard({ icon, title, description, className }: FeatureCardProps) {
  return (
    <div className={clsx(
      'bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow',
      className
    )}>
      <div className={`text-[${colors.primary.blue}] mb-4`}>
        {icon}
      </div>
      <h3 className="text-xl font-bold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
}