import { NavItem, FooterSection } from '../types';

export const mainNavItems: NavItem[] = [
  { label: 'Home', href: '/' },
  { label: 'Review Booster', href: '/products/review-booster' },
  { label: 'AI Phone Assistant', href: '/products/ai-phone-assistant' },
  { label: 'Blog', href: '/blog' },
  { label: 'Pricing', href: '/pricing' },
  { label: 'Contact', href: '/contact' },
];

export const footerSections: FooterSection[] = [
  {
    title: 'Products',
    links: [
      { label: 'Review Booster', href: '/products/review-booster' },
      { label: 'AI Phone Assistant', href: '/products/ai-phone-assistant' },
      { label: 'Pricing', href: '/pricing' },
    ],
  },
  {
    title: 'Resources',
    links: [
      { label: 'Blog', href: '/blog' },
      { label: 'Contact', href: '/contact' },
    ],
  },
];