import React from 'react';
import Hero from '../components/sections/home/Hero';
import Features from '../components/sections/home/Features';
import Industries from '../components/sections/home/Industries';
import Testimonials from '../components/sections/home/Testimonials';
import CTASection from '../components/sections/home/CTASection';
import { generateMeta } from '../utils/meta';

export default function Home() {
  const meta = generateMeta({
    description: 'AI-powered solutions for business growth in Edmonton. Get more reviews and automate calls with WTF Pro.'
  });

  return (
    <>
      <Hero />
      <Features />
      <Industries />
      <Testimonials />
      <CTASection />
    </>
  );
}