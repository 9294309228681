export const siteConfig = {
  name: 'Workflow Technology Facilitation',
  shortName: 'WTF Pro',
  domain: 'wtfpro.ca',
  url: 'https://www.wtfpro.ca',
  contact: {
    email: 'jeremy@wtfpro.ca',
    phone: '(780) 863-3991',
    address: '554 Hodgson Road',
    city: 'Edmonton',
    province: 'Alberta',
    postalCode: 'T6R 3L2',
    country: 'CA'
  },
  social: {
    linkedin: 'https://linkedin.com/company/wtfpro',
    facebook: 'https://facebook.com/wtfpro.ca',
    twitter: 'https://twitter.com/wtfpro_ca'
  }
};