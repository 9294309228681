import React from 'react';
import Layout from '../../components/Layout';
import { Section } from '../../components/ui/Section';
import { SectionHeading } from '../../components/ui/SectionHeading';
import { QRCodeGenerator } from '../../components/review/QRCodeGenerator';

const locations = [
  'Main Lobby',
  'Restaurant',
  'Spa',
  'Pool Area',
];

export default function Dashboard() {
  return (
    <Layout>
      <Section className="bg-white">
        <SectionHeading>QR Code Generator</SectionHeading>
        <p className="text-center text-gray-600 mb-12">
          Generate QR codes for different locations in your resort
        </p>
        
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {locations.map((location) => (
            <div key={location} className="flex flex-col items-center">
              <h3 className="text-xl font-bold text-[#154c79] mb-4">
                {location}
              </h3>
              <QRCodeGenerator location={location} />
            </div>
          ))}
        </div>
      </Section>
    </Layout>
  );
}