import React from 'react';

interface FormStatusProps {
  status: 'success' | 'error';
  messages: {
    success: string;
    error: string;
  };
}

export function FormStatus({ status, messages }: FormStatusProps) {
  if (!status || status === 'idle') return null;

  const styles = {
    success: 'bg-green-100 text-green-700',
    error: 'bg-red-100 text-red-700',
  };

  return (
    <div className={`p-4 rounded-md ${styles[status]}`}>
      {messages[status]}
    </div>
  );
}